<template>
  <div class="container-fluid mt-5">
    <vx-card title="Educator Onboarding" subtitle="" class="educator-onboarding">
      <div class="mt-5">
        <form-wizard 
          :title="null" :subtitle="null"
          color="rgba(var(--vs-primary), 1)"
          finishButtonText="Submit"
          @on-complete="formSubmitted"
          @on-error="handleErrorMessage"
        >
          <tab-content title="Step 1" class="mb-5" :before-change="validateFirstStep">
            <!-- tab 1 content -->
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full d-none d-md-block mt-5">
              <img
          class="card-img mb-0"
          src="@/assets/images/pages/Onboarding-01.png"
          alt=""
        />
              </div>

              <div class="vx-col md:w-1/2 w-full mt-5">
                <div class="">
                  <!-- Img Row -->
                  <div class="flex flex-wrap items-center">
                    <vs-avatar :src="image" size="70px" class="mr-4 mb-4 ml-0" />
                    <input type="file" ref="imageInput" class="hidden" @change="handleImageInputChange" />
                    <div>
                      <vs-button 
                        class="mr-4 sm:mb-0 mb-2"
                        @click="toggleUploadImage"
                        >Upload Profile Picture</vs-button
                      >
                    </div>
                  </div>
                </div>
                <!-- <div class="mt-5">
                  <label class="ml-1">Headline</label>
                  <vs-input
                    label=""
                    v-model="headline"
                    class="w-full"
                  />
                </div> -->
                <div class="mt-5">
                  <label class="ml-1">Headline/Short Description</label>
                  <vs-textarea
                    v-model="about"
                    class="mb-0"
                    rows=""
                  />
                </div>

                <div class="mt-5">
                  <label class="ml-1">Location</label>
                  <location-select @onLocSelect="handleLocSelect" :isBootstrap="true" />
                </div>
                <div class="mt-5">
                  <!-- <vs-select
                    class="w-full"
                    label="Languages"
                    v-model="languages"
                    multiple
                    :closeOnSelect="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  >
                    <vs-select-item v-for="(lang, index) in langOptions" :key="index" 
                      :value="lang.value" :text="lang.text"/>
                  </vs-select> -->
                  <label class="ml-1">Languages</label>
                  <v-select v-model="languages"
                    multiple
                    label="text"
                    :closeOnSelect="false"
                    :options="langOptions"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  />
                </div>
              </div>
            </div>
          </tab-content>

          <!-- tab 2 content -->
          <tab-content title="Step 2" class="mb-5" :before-change="validateSecondStep">
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full d-none d-md-block">
              <img
          class="card-img mb-0"
          src="@/assets/images/pages/Onboarding-02.png"
          alt=""
        />
              </div>
              <div class="vx-col md:w-1/2 w-full">
                <div class="mt-5">
                  <label class="ml-1">Select Account Type:</label>
                  <vs-select
                    v-model="profession"
                    class="w-full select-large"
                    label=""
                  >
                    <vs-select-item
                      :key="index"
                      :value="item"
                      :text="item"
                      v-for="(item, index) in professionOptions"
                      class="w-full"
                    />
                  </vs-select>
                </div>
                  <div class="mt-5">
                  <label class="">How long you have been in the industry?</label>
                  <vs-input
                    type="number"
                    label=""
                    @keydown="numberValidation"
                    v-model="yearOfExp"
                    class="w-full"
                  />  
                </div>  
                <!-- <div class="mt-5">
                  <label class="">Select Your Expertises</label>
                  <v-select
                    v-model="skills"
                    multiple
                    taggable
                    label="name"
                    :closeOnSelect="false"
                    :options="suggestSkillList"
                    @option:created="handleExpertiseOptionCreate"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  />
                </div> -->

                <div class="mt-5">
                  <div class="flex justify-content-between">
                    <label class="ml-1">Services Offered:</label>
                    <span
                      class="material-icons info ml-2 mb-1"
                      v-b-tooltip.hover.v-primary
                      title="Select services you offer"
                      >info</span
                    >
                  </div>
                  <v-select
                    v-model="servicesOffered"
                    multiple
                    :closeOnSelect="false"
                    :options="servicesOfferedList"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  />
                </div>

            <!--    <div class="mt-5">
                 <div class="flex justify-content-between">
                    <label class="ml-1">Study Area/Category</label>
                    <span
                      class="material-icons info ml-2 mb-1"
                      v-b-tooltip.hover.v-primary
                      title="Select study areas/calegories"
                      >info</span
                    >
                  </div>  

                  <v-select
                    v-model="category"
                    label="text"
                    :options="categories"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    @input="fetchTopic()"
                  />
                </div>-->

              <!--  <div class="mt-5">
                  z <vs-input class="w-full" v-model="subjects" label="Add Topics:" @change=""/> 
                  <label class="ml-1">Add Topics:</label>
                  <v-select
                    :disabled="category === ''"
                    v-model="subjects"
                    multiple
                    taggable
                    label="text"
                    :closeOnSelect="false"
                    :options="subjectList"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    @option:created="handleSubjectOptionCreate"
                  />
                </div>  -->
                
              </div>  
            </div> 
          </tab-content> 

          <!-- tab 3 content -->
          <tab-content title="Step 3" class="mb-5" @before-change="validateThirdStep">
            <div class="vx-row items-center">
              <div class="vx-col md:w-1/2 w-full d-none d-md-block">
               <img
          class="card-img mb-0"
          src="@/assets/images/pages/Onboarding-03.png"
          alt=""
        />
              </div>

              <div class="vx-col md:w-1/2 w-full mt-5">
               <div class="">
                  <h3 class="mb-5">Great work, Welcome to MyPals!</h3>
                  <p class="mb-5 lead">Click Submit button below to complete onboarding and become part of our community!</p>
                  <p class="lead mb-5">Feel free to contact us at support@coursepal.app if you have any questions.</p>
                  <p class="lead mb-5">Happy Networking!</p>
                  <p class="lead mb-5">Coursepal Team</p>
                </div>
              </div>
            </div>
          </tab-content>
        </form-wizard>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { languages } from '@/langOptions'
import LocationSelect from '../profile/LocationSelect.vue';
import axios from 'axios'
import { API, graphqlOperation } from '@aws-amplify/api'
import { updateUser, createEducator, createExpertise, createTopic  } from '@/graphql/mutations' 
import { createErrorObject, createSuccessObject } from '@/utils'
import { Storage } from '@aws-amplify/storage'
// import { listCategorys, topicsByCategory } from "@/graphql/queries";
import { listExpertises } from '@/graphql/queries'

import { v4 as uuidv4 } from 'uuid'
import awsExport from '@/aws-exports'
import S3 from 'aws-s3'
const config  = {
  bucketName: awsExport.aws_user_files_s3_bucket,
  dirName: 'profile',
  region: awsExport.aws_user_files_s3_bucket_region,
  accessKeyId:     process.env.VUE_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_KEY
}

const s3ClientCoverDir = new S3(config)

export default {
  name: "EducatorOnboarding",
  components: {
    FormWizard,
    TabContent,
    vSelect,
    flatPickr,
    LocationSelect,
  },
  
  data () {
    return {
      image: '',
      imageFile: '',
      about: '',
      location: '',
      languages: '',
      profession: '',
      professionOptions: [
        'Online Creator',
        'Institution',
        'Business',
        'Employer',
        'Clubs'
      ],
      skills: [],
      suggestSkillList: [],
      yearOfExp: '',
      servicesOffered: '',
      servicesOfferedList: [
        'Higher Education',
        'Online Education',
        'Student Recruitment',
        "Career Guidance",
        "Employment Services"
      ],
      categories: [],
      category: '',
      subjects: [],
      subjectList: []
    }
  },
  computed: {
    langOptions () {
      return languages.map(lang => { return {text: lang.name, value: lang.name}})
    }
  },
  watch: {
    // category () {
    //   this.subjects = []
    //   this.fetchTopic()
    // }
  },
  methods: {
     numberValidation (e) {
        const characterCode = e.key
        if (characterCode === 'Backspace' || characterCode === 'Tab') return

        
        const characterNumber = Number(characterCode)
        if (characterNumber >= 0 && characterNumber <= 9) {
            if (e.currentTarget.value && e.currentTarget.value.length) {
            return
            } else if (characterNumber === 0) {
            e.preventDefault()
            }
        } else {
            e.preventDefault()
        }
    },
    // async fetchCategory () {
    //   try {
    //     const res = await API.graphql(graphqlOperation(listCategorys))
    //     this.categories = res.data.listCategorys.items.map(item => { return { text: item.name, val: item.id }})
    //   } catch (err) {
    //     console.log('fetchCategory', err)
    //   } 
    // },    
    async fetchTopic () {
      try {
        let category = ''
        this.subjects = []
        this.subjectList = []
        category = this.category.val
        const res = await API.graphql(graphqlOperation(topicsByCategory, {
          category
        }))
        this.subjectList = res.data.topicsByCategory.items.map(item => {
          return { text: item.name, val: item.id }
        })
      } catch (err) {
        console.log('fetchTopic', err)
      }
    },
    toggleUploadImage () {
      this.$refs.imageInput.click()
    },
    handleImageInputChange (e) {
      if (e.target.files[0]) {
        const url = URL.createObjectURL(e.target.files[0])
        this.image = url
        this.imageFile = e.target.files[0]
      }
    },
    validateFirstStep () {
      return new Promise((resolve, reject) => 
      {
         if (this.about.length === 0) {
          reject('Please add short details about you.')
        }
        if (this.location.length === 0) {
          reject('Please add your location')
        }
         if (this.languages.length === 0) {
          reject('Please add languages you know. ')
        }
        
        resolve(true)
      })
    },
    validateSecondStep () {
      return new Promise((resolve, reject) => {
        if (this.profession === '') {
         reject('Please select your account type.')
        }

        if (this.yearOfExp === '') {
          reject('Please add your experience.')
        }

        if (parseInt(this.yearOfExp) === 'NaN') {
          reject('Years of experience field must be a number')
        }

        // if (this.skills.length === 0) {
        //   reject('Please add your expertises.')
        // }

        // if (this.category === '') {
        //  reject('Please select a category.')
      //  }

      //  if (this.subjects.length === 0) {
      //    reject('Please select topics.')
      //  }
        
        resolve(true)
      })
    },
    validateThirdStep () {
      // return new Promise((resolve, reject) => {
      //   if (this.category === '') {
      //     reject('Please select a category.')
      //   }

      //   if (this.subjects.length === 0) {
      //     reject('Please select topics.')
      //   }

      //   resolve(true)
      // })
    },
    handleLocSelect (location) {
      this.location = location
    },
    async formSubmitted () {
      try {
        this.$vs.loading()
        // const file = await Storage.put(this.imageFile.name, this.imageFile)
        let photoURL = process.env.VUE_APP_DEFAULT_IMAGE_URL;
        if(this.imageFile){
            const imgRes = await s3ClientCoverDir.uploadFile(this.imageFile, `profile-${uuidv4()}`)
            photoURL = imgRes.location;
        }
        

        const updateRes = await API.graphql(graphqlOperation(updateUser, {
          input: {
            name: this.$store.state.auth.userData.name,
            about: this.about,
            location: this.location,
            photoURL: photoURL,
            isFirstLogin: false
          }
        }))

        this.$store.commit('auth/UPDATE_USER_INFO', updateRes.data.updateUser)

        await API.graphql(graphqlOperation(createEducator, {
          input: {
            user: this.$store.state.auth.userData.name,
            languages: this.languages.map(lang => lang.value),
            skills: this.skills.map(sk => sk.name),
            yearOfExp: this.yearOfExp,
            profession: this.profession,
            servicesOffered: this.servicesOffered,
            category: this.category.text,
            subjects: this.subjects.map(subject => subject.text)
          }
        }))

        this.$vs.notify(createSuccessObject('Success', 'Your profile created successfully.'))
        this.$router.push('/connect')
        this.$vs.loading.close()
      } catch (err) {
        this.$vs.notify(createErrorObject('Error','Unable to update user data'))
        console.log(err)
      }
    },
    async handleSubjectOptionCreate (newOption) {
      let name = newOption.text ? newOption.text : newOption
      try {
        const res = await API.graphql(
          graphqlOperation(createTopic, {
            input: {
              category: this.category.val,
              name,
            },
          })
        );
        
        this.subjectList.push({
          text: name,
          val: res.data.createTopic.id,
        });
      } catch (err) {
        console.log('handleSubjectOptionCreate', err)
      }
    },
    handleExpertiseOptionCreate (newExpertise) {
      API.graphql(graphqlOperation(createExpertise, {
        input: {
          name: newExpertise.name
        }
      })).then(res => {
        this.suggestSkillList.push(res.data.createExpertise)
      })
    },
    handleErrorMessage (msg) {
      if (msg) {
        this.$vs.notify(createErrorObject('Error', msg))
      }
    }
  },
  created () {
   // axios.post(`${process.env.VUE_APP_BASE_URL}/StudyAreaList`).then(res => {
    //  this.categories = res.data.data.map(sa => { return {text: sa.study_area, val: sa.stu_area_id}})
   // })
  //  this.fetchCategory()
  //   API.graphql(graphqlOperation(listExpertises)).then(res => {
  //     this.suggestSkillList = res.data.listExpertises.items
  //   }).catch(err => console.log(err))
  }
};
</script>

<style lang="scss">
.tip-note {
  background-color: rgb(246, 248, 253);
  border: 1px solid rgb(231, 235, 251);
  border-radius: 4px;
  padding: 16px;
  margin-top: 20px;
}
.tip-note h3 {
  color: rgb(41, 43, 50) !important;
}
.tip-note span {
  color: rgb(84, 90, 119) !important;
}
.tip-note .feather {
  color: rgb(84, 90, 119) !important;
}

</style>
